import Pristine from 'pristinejs/dist/pristine';
import { serializeFormData } from '../helpers/helpers';
import { MAILCHIMP_SERVICE } from '../services/mailchimpService';

const HANDLES = {
    init() {
        this.mailchimpSubscribeForm();
    },
    mailchimpSubscribeForm() {
        const form = document.getElementById('mailchimp-subscribe-form');
        const pristine = new Pristine(form);
        form.addEventListener('submit', (e) => {
            e.preventDefault();
            let valid = pristine.validate();
            if (valid) {
                document.querySelector('#mailchimp-subscribe-form button svg').classList.remove('hidden');
                let serializedForm = serializeFormData(form);
                return MAILCHIMP_SERVICE.setListMember(serializedForm);
            }
        });
    }
}

export { HANDLES }