export const downloadFile = (fileURL, fileName) => {
    // for non-IE
    if (!window.ActiveXObject) {
        let save = document.createElement('a');
        save.href = fileURL;
        save.target = '_blank';
        let filename = fileURL.substring(fileURL.lastIndexOf('/')+1);
        save.download = fileName || filename;
        if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
            document.location = save.href;
        // window event not working here
        } else {
            let evt = new MouseEvent('click', {
                'view': window,
                'bubbles': true,
                'cancelable': false
            });
            save.dispatchEvent(evt);
            (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
    }
    // for IE < 11
    else if ( !! window.ActiveXObject && document.execCommand) {
        let _window = window.open(fileURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName || fileURL)
        _window.close();
    }
}

export const serializeFormData = (form) => {
    const formData = new FormData(form);
    let serializedData = {};

    for (let [name, value] of formData) {
        if (serializedData[name]) {
            if (!Array.isArray(serializedData[name])) {
                serializedData[name] = [serializedData[name]];
            }
            if (value !== '') {
                serializedData[name].push(value);
            }
        } else {
            if (value !== '') {
                serializedData[name] = value;
            }
        }
    }
    return serializedData;
}