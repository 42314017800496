/* Import CSS Plugins and main SCSS */
import 'animate.css/animate.min.css';
import 'aos/dist/aos.css';
import 'vanilla-cookieconsent/dist/cookieconsent.css'

/* Import Js Plugins (ES6 Module) and custom JS */
import AOS from 'aos';
import Rellax from 'rellax';
import ActiveMenuLink from 'active-menu-link';
import GLightbox from 'glightbox';
import Vivus from 'vivus';
import { Accordion, Modal } from 'flowbite';

import '../plugins/cookieconsent-init';

/* Global variables */
let lastScroll = 0;
let delta = 5;
let headerHeight = document.querySelector('header').offsetHeight;

const UTILS = {
    init() {
        AOS.init();
        new Rellax('.rellax');
        GLightbox({
            touchNavigation: true,
            autoplayVideos: true
        });
        this.activeMenuLink();
        this.topNavigationMenu();
        this.drawLineHowItWorks();
        this.story('.pt__services-desktop .story-texts [data-text-story]', '180px', '.pt__services-desktop .story-images');
        this.story('.pt__app-screenshots-desktop .story-texts [data-text-story]', '60px', '.pt__app-screenshots-desktop .story-images');
        this.copyPrintYear();
    },
    activeMenuLink() {
        let options = {
            //showHash: false,
        };
        new ActiveMenuLink('.pt__top-nav', options);
    },
    topNavigationMenu() {
        let toggleMenuButton = document.querySelector('#toggle-menu-button');
        let menu = document.querySelector('#toggle-menu');
        let openMenuButton = document.querySelector('#open-menu-button');
        let closeMenuButton = document.querySelector('#close-menu-button');
        let itemMenu = document.querySelectorAll('#toggle-menu a');

        toggleMenuButton.addEventListener('click', (e) => {
            menu.classList.toggle('hidden');
            openMenuButton.classList.toggle('hidden');
            closeMenuButton.classList.toggle('hidden');
        });
        itemMenu.forEach((el, index) => {
            el.addEventListener('click', (e) => {
                e.preventDefault()
                toggleMenuButton.click();
            });
        });
    },
    headerAnimate() {
        let scrollTop = document.documentElement.scrollTop
        if (Math.abs(lastScroll - scrollTop) <= delta)
            return;
        if (scrollTop > lastScroll && scrollTop > headerHeight) {
            // scroll down
            document.querySelector('header').classList.remove('down');
            document.querySelector('header').classList.add('up');
        } else {
            // scroll up
            if (scrollTop + window.innerHeight < document.documentElement.scrollHeight) {
                document.querySelector('header').classList.remove('up');
                document.querySelector('header').classList.add('down');
            }
            if (scrollTop <= 50) {
                document.querySelector('header').classList.remove('down');
            }
        }
        lastScroll = scrollTop;
    },
    drawLineHowItWorks() {
        const element = new Vivus('draw-line-how-it-works', {
            duration: 200,
            file: `${process.env.BASE_URL}/assets/img/line-how-it-works.svg`
        });
        element.stop().reset().play(1);
    },
    observerStory(entries, observer, el, elementObserver) {
        entries.forEach((entry, i) => {
            let storyImage = document.querySelector(`${elementObserver} img[data-image-story="${entry.target.dataset.textStory}"]`);
            if (entry.isIntersecting) {
                storyImage.classList.remove('hidden');
                storyImage.classList.add('animate__fadeIn');
                entry.target.classList.add('active');
            }
            else {
                storyImage.classList.add('hidden');
                storyImage.classList.remove('animate__fadeIn');
                entry.target.classList.remove('active');
            }
        });
    },
    story(element, rootMargin, elementObserver) {
        document.querySelectorAll(element).forEach((el) => {
            if (el) {
                const observer = new IntersectionObserver((entries) => {
                        this.observerStory(entries, observer, el, elementObserver)
                    },
                    {
                        threshold: 1,
                        rootMargin: rootMargin
                    });
                observer.observe(el);
            }
        })
    },
    copyPrintYear() {
        const year = new Date().getFullYear();
        document.querySelector('footer .year').innerText = year;
    }
}

export { UTILS }